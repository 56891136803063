<template>
  <section class="Project">
    <!-- 页面 header 通屏大图 -->
    <FullBanner :bannerURL="$route.meta.img" moduleName="Event">
      <template v-slot:customFill>
        <iq-card class="iq-card">
          <section class="container">
            <el-row class="py-3">
              <el-col :span="24">
                <el-input clearable @clear="getEventList" @keyup.enter.native="getEventList" class="w-100"
                  :placeholder="$t('Events.94f@search_event')" v-model="search"></el-input>
              </el-col>
            </el-row>
            <el-row :gutter="5" class="pb-3">
              <el-col :xs="12" :sm="4">
                <el-date-picker style="width: 100%" clearable v-model="date" @change="getEventList" type="date"
                  :placeholder="$t('Events.c0f@date')" value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-col>
              <el-col :xs="12" :sm="4" class="pb-3">
                <el-select clearable @clear="getEventList" v-model="country_id" @change="getEventList"
                  :placeholder="$t('Events.e97@country')" filterable >
                  <el-option v-for="(v, i) in countryList" :key="i" :label="v.name" :value="v.id">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :xs="24" :sm="16">
                <OrganazationSel @getOrganizationParams="getOrganizationParams" />
              </el-col>
            </el-row>
          </section>
          <section class="container">
            <tab-nav :tabs="true" id="myTab-two" class="tabsWidthScroll">
              <tab-nav-items :active="active == 'explore'" href="#explore" title="Explore"
                @click.native="((page = 1), (type = 0)), getEventList()" />
              <tab-nav-items :active="active == 'my_event'" href="#my_event" title="My Event"
                @click.native="((page = 1), (type = 1)), getEventList()" />
            </tab-nav>
          </section>
        </iq-card>
      </template>
    </FullBanner>

    <tab-content>
      <tab-content-item :active="active == 'explore'"  aria-labelled-by="prerecording-tab-two">
        <div class="container">
          <b-row v-if="loading">
            <b-col v-for="(item, index) in 12" :key="index" md="6" lg="4">
              <el-skeleton animated>
                <template slot="template">
                  <iq-card body-class="p-0" style="border-radius: 5px;overflow: hidden;">
                    <el-skeleton-item variant="image" style="width: 100%; height: 160px;" />
                    <div style="padding: 22px 10px 20px;" class="d-flex flex-column">
                      <el-skeleton-item variant="text" style="width: 60%;margin-bottom: 20px;" />
                      <el-skeleton-item variant="text" style="width: 40%;margin-bottom: 20px;" />
                      <el-skeleton-item variant="text" style="width: 60%" />
                    </div>
                  </iq-card>
                </template>
              </el-skeleton>
            </b-col>
          </b-row>
          <b-row v-if="EventList.length&&!loading">
            <b-col v-for="item in EventList" :key="item.id" md="6" lg="4">
              <Prerecording @click.native="$router.push(`/event-detail?id=${item.id}&type=2&IsShowCheckout=${type==1?0:1}`)" :cardInfo="item" />
            </b-col>
          </b-row>
        </div>
        <b-row v-if="!EventList.length" class="d-flex justify-content-center" style="margin-top: 30px">
          <div style="background:#fff;width:960px;text-align:center;padding:50px">
            <img style="width:250px" src="../../assets/images/group/empty1.png" alt="" v-if="!loading" />
          </div>
        </b-row>
      </tab-content-item>
      <tab-content-item :active="active == 'my_event'">
        <b-row v-if="EventList.length&&!loading">
          <b-col v-for="item in EventList" :key="item.id" md="6" lg="4">
            <Prerecording @click.native="$router.push(`/event-detail?id=${item.id}&type=2&IsShowCheckout=${type==1?0:1}`)" :cardInfo="item" />
          </b-col>
        </b-row>
        <b-row v-if="!EventList.length" class="d-flex justify-content-center">
          <div style="background:#fff;width:960px;text-align:center;padding:50px">
            <img style="width:250px" src="../../assets/images/group/empty1.png" alt="" v-if="!loading" />
          </div>
        </b-row>
      </tab-content-item>
    </tab-content>

    <div v-if="EventList.length" class="d-flex justify-content-end container">
      <el-pagination background layout="prev, pager, next" :page-size="size" @current-change="handleCurrentChange"
        :current-page.sync="page" :total="total" hide-on-single-page>
      </el-pagination>
    </div>
  </section>
</template>
<script>
import FullBanner from '@/components/ComComponents/FullBanner'
import CountrySelection from '@/components/ComComponents/CountrySelection'
import Prerecording from './components/Prerecording'
import Pagination from '@/components/ComComponents/Pagination'
import OrganazationSel from '@/components/ComComponents/OrganazationSel'
export default {
  components: {
    FullBanner,
    CountrySelection,
    Prerecording,
    Pagination,
    OrganazationSel
  },
  data () {
    return {
      active: 'explore',
      EventList: [],
      type: 0,
      search: '',
      country_id: '',
      countryList: [],
      date: '',

      page: 1,
      total: 0,
      size: 12,
      loading: true
    }
  },
  created () {
    this.getEventList()
    this.getAddress()
  },

  methods: {
    // 获取国家列表
    async getAddress (level = 2) {
      const params = {
        level
      }
      let { status, data } = await this.$http.getAddress(params)
      if (status == 200) {
        this.countryList = data
      }
    },
    getOrganizationParams (data) {
      let OrganData = JSON.parse(JSON.stringify(data))
      let level = OrganData.level.id
      switch (level) {
        case 0: {
          // jci
          this.organization_id = OrganData.jci.id
          this.getEventList()
          break
        }
        case 4: {
          // area
          this.organization_id = OrganData.area.id
          this.getEventList()
          break
        }
        case 2: {
          // nation
          this.organization_id = OrganData.national.id
          this.getEventList()
          break
        }
        case 3: {
          // region
          this.organization_id = OrganData.regional.id
          this.getEventList()
          break
        }
        case 1: {
          // local
          this.organization_id = OrganData.local.id
          this.getEventList()
          break
        }
      }
    },
    handleCurrentChange (page) {
      this.page = page
      this.getEventList()
    },

    // 获取项目列表
    getEventList (params) {
      this.loading = true
      this.$http.eventList({
        keyword: this.search,
        date: this.date,
        country_id: this.country_id,
        organization_id: this.organization_id,
        per_page: this.size,
        type: this.type,
        page: this.page
      }).then((res) => {
        if (res.status == 200) {
          this.total = res.data.total
          this.EventList = res.data.data
        }
        setTimeout(() => {
          this.loading = false
        }, 0)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .Project {
    min-height: 100vh;

    .title {
      margin-bottom: 18px;
    }

    .profile-info {
      min-height: 130px;
    }

    .searchBox {
      // padding: 0 8px;
      position: relative;

      img {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }
  }
  // @media (max-width: 500px) {
  //   ::v-deep .nav-tabs .nav-item a{
  //     font-size: 10px;
  //   }
  // }

  ::v-deep .el-date-editor--date .el-input__inner,
  ::v-deep .el-select .el-input__inner,
  ::v-deep .el-input__inner {
    height: 45px;
    border-radius: 11px;
  }
</style>
